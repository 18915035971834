import api from ".";

export const getSubAdmins = () => {
    return api.get("/sub-admins");
};

export const createSubAdmin = (id, name, password) => {
    return api.post("/sub-admins", { id, name, password });
};

export const transferBeans = (receiver, amount) => {
    return api.post("/transfer-beans", { amount, receiver_user_id: receiver });
};

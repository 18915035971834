import { configureStore } from "@reduxjs/toolkit";
import auth from "./slice/auth";
import stats from "./slice/stats";

const store = configureStore({
  reducer: {
    auth,
    stats,
  },
});

export default store;

import React, { useEffect, useState } from "react";
// react-bootstrap components
import { Card, Container, Row, Col } from "react-bootstrap";
import moment from "moment";
import { getSessions } from "api/user";
import { useSelector } from "react-redux";
import { deleteSession } from "api/user";

function Sessions() {
  const user = useSelector((state) => state.auth.user);

  const [sessions, setSessions] = useState([]);

  const loadSessions = async () => {
    try {
      const { data } = await getSessions();
      setSessions(data.sessions);
    } catch (error) {}
  };

  useEffect(() => {
    loadSessions();
  }, []);

  const handleRemove = (sessionId) => {
    if (confirm("Are you sure?")) {
      setSessions((prev) => prev.filter((s) => s._id != sessionId));

      deleteSession(sessionId);
    }
  };

  return (
    <>
      <Container fluid>
        <Row>
          <Col md="12">
            <Card className="card-plain table-plain-bg">
              <Card.Header>
                <Card.Title as="h4">Access Session List</Card.Title>
              </Card.Header>
              <Card.Body className="px-0">
                <Row>
                  {sessions.map((session, idx) => (
                    <Col
                      key={idx}
                      md={6}
                      lg={4}
                      sm={12}
                      className="p-2 border position-relative"
                      style={{
                        backgroundColor: JSON.parse(window.atob(user?.jwt.split(".")[1])).sub == session._id ? "#00ff0038" : "unset",
                      }}
                    >
                      <div>{session?.userAgent}</div>
                      <div>
                        <strong>Ip: </strong>
                        {session.ip}
                      </div>
                      <div>
                        <strong>Started At: </strong>
                        {moment(session?.createdAt).format("lll")}
                      </div>
                      <div>
                        <strong>Last Activity: </strong>
                        {moment(session?.updatedAt).fromNow()}
                      </div>

                      <button onClick={() => handleRemove(session._id)} className="position-absolute top-0 end-0 m-2 btn btn-danger btn-sm">
                        Remove
                      </button>
                    </Col>
                  ))}
                </Row>
              </Card.Body>
            </Card>
          </Col>
        </Row>
      </Container>
    </>
  );
}

export default Sessions;

import React, { useEffect, useId, useState } from "react";
import {
  Button,
  Card,
  Container,
  Row,
  Col,
  Form,
  Spinner,
} from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import { numberFormatter } from "utils/number";
import { getState } from "api/stats";
import { addState } from "store/slice/stats";
import { NotificationManager } from "react-notifications";
import { sendBeans } from "api/transaction";
import { incrementTransaction } from "store/slice/stats";
import { decrementBeans } from "store/slice/stats";
import { getUser } from "api/user";

function Dashboard() {
  const dispatch = useDispatch();
  const stats = useSelector((state) => state.stats);

  const [userID, setUserID] = useState("");
  const [amount, setAmount] = useState("");
  const [confirmAmount, setConfirmAmount] = useState("");
  const [sending, setSending] = useState(false);

  const [user, setUser] = useState();

  const handleSendBeans = async (e) => {
    try {
      e.preventDefault();

      if (amount !== confirmAmount) {
        NotificationManager.error(
          "The amount and confirm amount doesn't match."
        );
        return;
      }

      setSending(true);

      await sendBeans(userID, amount);

      dispatch(incrementTransaction(1));
      dispatch(decrementBeans(amount));
      setUserID("");
      setAmount("");
      setConfirmAmount("");

      NotificationManager.success("Beans successfully sent.");
      setSending(false);
    } catch (error) {
      setSending(false);

      NotificationManager.error(
        error?.response?.data?.issue?.message || error.message
      );
    }
  };

  const loadStats = async () => {
    try {
      const { data } = await getState();

      dispatch(
        addState({
          beans: data?.availableBeans || 0,
          transactions: data?.totalTransactions || 0,
        })
      );
    } catch (error) {
      NotificationManager.error(
        error?.response?.data?.issue?.message || error.message
      );
    }
  };

  useEffect(() => {
    loadStats();
  }, []);

  const getUserInfo = async (userId) => {
    try {
      const { data } = await getUser(userId);

      setUser(data?.user);
    } catch (error) {
      console.log(error);
      NotificationManager.error(
        error?.response?.data?.issue?.message || error.message
      );
    }
  };

  useEffect(() => {
    if (userID.length === 8) getUserInfo(userID);
    else setUser(null);
  }, [userID.length]);

  return (
    <>
      <Container fluid>
        <Row>
          <Col sm="6">
            <Card
              className="card-stats shadow-sm"
              style={{
                borderTopWidth: "8px",
              }}
            >
              <Card.Body>
                <Row>
                  <Col xs="5">
                    <div className="icon-big text-center icon-warning">
                      <i className="nc-icon nc-chart text-warning"></i>
                    </div>
                  </Col>
                  <Col xs="7">
                    <div className="numbers">
                      <p className="card-category">Available Beans</p>
                      <Card.Title as="h4">
                        {numberFormatter(stats.beans)}
                      </Card.Title>
                    </div>
                  </Col>
                </Row>
              </Card.Body>
              {/* <Card.Footer>
                <hr></hr>
                <div className="stats">
                  <i className="fas fa-redo me-1"></i>
                  Just now
                </div>
              </Card.Footer> */}
            </Card>
          </Col>
          <Col sm="6">
            <Card
              className="card-stats shadow-sm"
              style={{
                borderTopWidth: "8px",
              }}
            >
              <Card.Body>
                <Row>
                  <Col xs="5">
                    <div className="icon-big text-center icon-warning">
                      <i className="nc-icon nc-delivery-fast text-success"></i>
                    </div>
                  </Col>
                  <Col xs="7">
                    <div className="numbers">
                      <p className="card-category">Total Transactions</p>
                      <Card.Title as="h4">
                        {numberFormatter(stats.transactions)}
                      </Card.Title>
                    </div>
                  </Col>
                </Row>
              </Card.Body>
              {/* <Card.Footer>
                <hr></hr>
                <div className="stats">
                  <i className="far fa-calendar-alt me-1"></i>
                  Last day
                </div>
              </Card.Footer> */}
            </Card>
          </Col>

          <Col xs={12}>
            <form onSubmit={handleSendBeans}>
              <Card
                className="card-stats px-3 pb-4 shadow-sm"
                style={{
                  borderTopWidth: "8px",
                }}
              >
                <Card.Header>
                  <h3 className="text-bold text-center">SEND BEANS</h3>
                </Card.Header>

                <Form.Group className="mb-3">
                  <Form.Label>User ID</Form.Label>
                  <Form.Control
                    required
                    type="number"
                    placeholder="eg: 21000021"
                    value={userID}
                    onChange={(e) =>
                      e.target.value.length <= 8 && setUserID(e.target.value)
                    }
                  />
                </Form.Group>

                {user && (
                  <div className="p-2 d-flex mb-3 border rounded align-items-center">
                    <div className="me-2">
                      <img
                        src={user?.avatar}
                        alt="avatar"
                        style={{
                          width: "50px",
                          height: "50px",
                          borderRadius: "50%",
                        }}
                      />
                    </div>
                    <div>
                      <p className="m-0" style={{ fontWeight: 900 }}>
                        {user?.nickname}
                      </p>

                      <div style={{ fontSize: 15 }}>
                        <span className="m-0 me-2">
                          <strong>ID:</strong> {user?.userId}
                        </span>{" "}
                        <span className="m-0">
                          <strong>Level:</strong> {user?.level}
                        </span>
                      </div>
                    </div>
                  </div>
                )}

                <Form.Group className="mb-3">
                  <Form.Label>Amount</Form.Label>
                  <Form.Control
                    required
                    type="number"
                    placeholder="eg: 500"
                    value={amount}
                    onChange={(e) => setAmount(e.target.value)}
                  />
                </Form.Group>
                <Form.Group className="mb-3">
                  <Form.Label>Confirm amount</Form.Label>
                  <Form.Control
                    required
                    type="number"
                    placeholder="eg: 500"
                    value={confirmAmount}
                    onChange={(e) => setConfirmAmount(e.target.value)}
                  />
                </Form.Group>

                {sending ? (
                  <div className="text-center py-2">
                    <Spinner animation="grow" size="sm" />
                  </div>
                ) : (
                  <Button type="submit" disabled={sending}>
                    SEND
                  </Button>
                )}
              </Card>
            </form>
          </Col>
        </Row>
      </Container>
    </>
  );
}

export default Dashboard;

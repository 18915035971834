import { getHistory } from "api/transaction";
import React, { useEffect, useState } from "react";
import InfiniteScroll from "react-infinite-scroll-component";
// react-bootstrap components
import { Badge, Button, Card, Navbar, Nav, Table, Container, Row, Col, Spinner } from "react-bootstrap";
import moment from "moment";
function TableList() {
  const [transactions, setTransactions] = useState([]);
  const [hasMore, setHasMore] = useState(true);

  const loadHistory = async () => {
    try {
      const { data } = await getHistory(transactions.length, 20);
      setTransactions((prev) => [...prev, ...data.history]);
      setHasMore(data.history.length === 20);
    } catch (error) {}
  };

  useEffect(() => {
    loadHistory();
  }, []);

  return (
    <>
      <Container fluid>
        <Row>
          <InfiniteScroll
            dataLength={transactions.length} //This is important field to render the next data
            next={loadHistory}
            hasMore={hasMore}
            loader={
              <div className="text-center py-4">
                <Spinner animation="grow" />
              </div>
            }
            scrollableTarget="main-panel"
          >
            <Col md="12">
              <Card className="card-plain table-plain-bg">
                <Card.Header>
                  <Card.Title as="h4">Beans Send History</Card.Title>
                  <p className="card-category">Scroll to see all history.</p>
                </Card.Header>
                <Card.Body className="table-full-width table-responsive px-0">
                  <Table className="table-hover">
                    <thead>
                      <tr>
                        <th className="border-0">SL</th>
                        <th className="border-0">User ID</th>
                        <th className="border-0">Name</th>
                        <th className="border-0">Beans</th>
                        <th className="border-0">Date</th>
                      </tr>
                    </thead>
                    <tbody>
                      {transactions.map((transaction, idx) => (
                        <tr key={idx}>
                          <td>{idx + 1}</td>
                          <td>{transaction?.user?.userId}</td>
                          <td>{transaction?.user?.nickname}</td>
                          <td>{transaction?.amount}</td>
                          <td>{moment(transaction?.createdAt).format("lll")}</td>
                        </tr>
                      ))}
                    </tbody>
                  </Table>
                </Card.Body>
              </Card>
            </Col>
          </InfiniteScroll>
        </Row>
      </Container>
    </>
  );
}

export default TableList;

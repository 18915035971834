import api from ".";

export const getUser = (userId) => {
  return api.get(`/user/${userId}`);
};

export const getSessions = () => {
  return api.get("/sessions");
};

export const deleteSession = (sessionId) => {
  return api.delete("/delete-session", {
    data: {
      session_id: sessionId,
    },
  });
};

import { transferBeans } from "api/subAdmin";
import { createSubAdmin } from "api/subAdmin";
import React, { useState } from "react";
import { Button, Form, Modal, ModalBody, ModalHeader, ModalTitle, Spinner } from "react-bootstrap";
import { NotificationManager } from "react-notifications";
import { CardTitle } from "reactstrap";

export default function TransferBeans({ show, onSave, onClose }) {
    const [amount, setAmount] = useState("");

    const [sending, setSending] = useState(false);

    const resetStats = () => {
        setAmount("");
        setSending(false);
    };

    const handleSubmit = async (e) => {
        e.preventDefault();

        if (sending) return;

        try {
            setSending(true);
            await transferBeans(show._id, amount);
            setSending(false);

            resetStats();
            onClose();

            onSave();
        } catch (error) {
            NotificationManager.error(error?.response?.data?.issue?.message || error.message);
            setSending(false);
            console.log(error);
        }
    };

    return (
        <Modal
            show={show}
            onHide={() => {
                resetStats();
                onClose();
            }}
        >
            <ModalHeader closeButton>
                <ModalTitle as="h5">Transfer Beans</ModalTitle>
            </ModalHeader>

            <form onSubmit={handleSubmit}>
                <ModalBody>
                    <Form.Group className="mb-3">
                        <Form.Label>User ID</Form.Label>
                        <Form.Control
                            required
                            type="number"
                            placeholder="eg: 21000021"
                            onChange={(e) => setUserId(e.target.value)}
                            value={show?.unique_id}
                            disabled
                        />
                    </Form.Group>

                    <Form.Group className="mb-3">
                        <Form.Label>Beans</Form.Label>
                        <Form.Control
                            type="number"
                            required
                            placeholder="Enter beans amount"
                            onChange={(e) => setAmount(e.target.value)}
                            value={amount}
                        />
                    </Form.Group>

                    <div className="text-center">
                        <Button
                            type="submit"
                            style={{
                                minWidth: "100px",
                            }}
                        >
                            {sending ? <Spinner animation="grow" size="sm" /> : "Transfer"}
                        </Button>
                    </div>
                </ModalBody>
            </form>
        </Modal>
    );
}

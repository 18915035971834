import { getHistory } from "api/transaction";
import React, { useEffect, useState } from "react";
import InfiniteScroll from "react-infinite-scroll-component";
// react-bootstrap components
import { Badge, Button, Card, Navbar, Nav, Table, Container, Row, Col, Spinner } from "react-bootstrap";
import moment from "moment";
import { getSubAdmins } from "api/subAdmin";
import CreateSubAdmin from "components/modals/CreateSubAdmin";
import TransferBeans from "components/modals/TransferBeans";

function SubAdmin() {
    const [subAdmins, setSubAdmins] = useState([]);
    const [hasMore, setHasMore] = useState(true);

    const loadHistory = async () => {
        try {
            setHasMore(true);
            const { data } = await getSubAdmins(subAdmins.length, 20);
            setSubAdmins((prev) => [...prev, ...data.aubAdmins]);
            setHasMore(data.aubAdmins.length === 20);
        } catch (error) {}
    };

    useEffect(() => {
        loadHistory();
    }, []);

    const [create, setCreate] = useState(false);
    const [transfer, setTransfer] = useState(null);

    return (
        <>
            <Container fluid>
                <CreateSubAdmin
                    show={create}
                    onClose={() => setCreate(!create)}
                    onSave={() => {
                        setSubAdmins([]);
                        loadHistory();
                    }}
                />

                <TransferBeans
                    show={transfer}
                    onClose={() => setTransfer(null)}
                    onSave={() => {
                        setSubAdmins([]);
                        loadHistory();
                    }}
                />
                <Row>
                    <InfiniteScroll
                        dataLength={subAdmins.length} //This is important field to render the next data
                        next={loadHistory}
                        hasMore={hasMore}
                        loader={
                            <div className="text-center py-4">
                                <Spinner animation="grow" />
                            </div>
                        }
                        scrollableTarget="main-panel"
                    >
                        <Col md="12">
                            <Card className="card-plain table-plain-bg">
                                <Card.Header className="d-flex justify-content-between">
                                    <div>
                                        <Card.Title as="h4">Your admins</Card.Title>
                                        <p className="card-category">Scroll to see all admin.</p>
                                    </div>
                                    <div>
                                        <Button size="sm" onClick={() => setCreate(!create)}>
                                            Add new admin
                                        </Button>
                                    </div>
                                </Card.Header>
                                <Card.Body className="table-full-width table-responsive px-0">
                                    <Table className="table-hover">
                                        <thead>
                                            <tr>
                                                <th className="border-0">ID</th>
                                                <th className="border-0">Name</th>
                                                <th className="border-0">Beans</th>
                                                <th className="border-0">Action</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {subAdmins.map((admin, idx) => (
                                                <tr key={idx}>
                                                    <td>{admin?.unique_id}</td>
                                                    <td>{admin?.name}</td>
                                                    <td>{admin?.beans}</td>
                                                    <td>
                                                        <Button size="sm" onClick={() => setTransfer(admin)}>Transfer Beans</Button>
                                                    </td>
                                                </tr>
                                            ))}
                                        </tbody>
                                    </Table>
                                </Card.Body>
                            </Card>
                        </Col>
                    </InfiniteScroll>
                </Row>
            </Container>
        </>
    );
}

export default SubAdmin;

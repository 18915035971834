import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  beans: 0,
  transactions: 0,
};

export const stats = createSlice({
  name: "stats",
  initialState,
  reducers: {
    addState: (state, { payload }) => {
      return state = payload;
    },
    incrementTransaction: (state, { payload }) => {
      state.transactions = state.transactions + payload;
    },
    decrementBeans: (state, { payload }) => {
      state.beans = state.beans - payload;
    },
  },
});

export const { addState, incrementTransaction, decrementBeans } = stats.actions;

export default stats.reducer;

import api from ".";

export const sendBeans = (userID, amount) => {
  return api.post("/send-beans", {
    receiver_user_id: userID,
    amount,
  });
};

export const getHistory = (skip = 0, limit = 20) => {
  return api.get("/recharge-history", {
    params: {
      skip,
      limit,
    },
  });
};

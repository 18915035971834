import { createSubAdmin } from "api/subAdmin";
import React, { useState } from "react";
import { Button, Form, Modal, ModalBody, ModalHeader, ModalTitle, Spinner } from "react-bootstrap";
import { NotificationManager } from "react-notifications";
import { CardTitle } from "reactstrap";

export default function CreateSubAdmin({ show, onSave, onClose }) {
  const [userId, setUserId] = useState(Math.floor(Math.random() * 900000) + 100000);
  const [name, setName] = useState("");
  const [password, setPassword] = useState("");

  const [creating, setCreating] = useState(false);

  const resetStats = () => {
    setUserId(Math.floor(Math.random() * 900000) + 100000);
    setName("");
    setPassword("");
    setCreating(false);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    if (creating) return;

    try {
      setCreating(true);
      await createSubAdmin(userId, name, password);
      setCreating(false);

      resetStats();
      onClose();

      onSave();
    } catch (error) {
      NotificationManager.error(error?.response?.data?.issue?.message || error.message);
      setCreating(false);
      console.log(error);
    }
  };

  return (
    <Modal
      show={show}
      onHide={() => {
        resetStats();
        onClose();
      }}
    >
      <ModalHeader closeButton>
        <ModalTitle as="h5">Add Reseller Portal</ModalTitle>
      </ModalHeader>

      <form onSubmit={handleSubmit}>
        <ModalBody>
          <Form.Group className="mb-3">
            <Form.Label>User ID</Form.Label>
            <Form.Control required type="number" placeholder="eg: 21000021" onChange={(e) => setUserId(e.target.value)} value={userId} disabled />
          </Form.Group>

          <Form.Group className="mb-3">
            <Form.Label>Name</Form.Label>
            <Form.Control required placeholder="Enter name" onChange={(e) => setName(e.target.value)} value={name} />
          </Form.Group>

          <Form.Group className="mb-3">
            <Form.Label>Password</Form.Label>
            <Form.Control required placeholder="Enter password" onChange={(e) => setPassword(e.target.value)} value={password} />
          </Form.Group>

          <div className="text-center">
            <Button
              type="submit"
              style={{
                minWidth: "100px",
              }}
            >
              {creating ? <Spinner animation="grow" size="sm" /> : "Add"}
            </Button>
          </div>
        </ModalBody>
      </form>
    </Modal>
  );
}

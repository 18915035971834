import Dashboard from "pages/Dashboard";
import History from "pages/History";
import Icons from "pages/Icons";
import Sessions from "pages/Sessions";
import SubAdmin from "pages/SubAdmin";

const dashboardRoutes = [
  {
    path: "/dashboard",
    name: "Dashboard",
    icon: "nc-icon nc-chart-pie-35",
    component: Dashboard,
    layout: "/admin",
  },
  {
    path: "/history",
    name: "History",
    icon: "nc-icon nc-align-left-2",
    component: History,
    layout: "/admin",
  },
  // {
  //   path: "/iocns",
  //   name: "iocns",
  //   icon: "nc-icon nc-single-02",
  //   component: Icons,
  //   layout: "/admin",
  // },
  {
    path: "/sub-admin",
    name: "Reseller Portal",
    icon: "nc-icon nc-key-25",
    component: SubAdmin,
    layout: "/admin",
  },
  {
    path: "/sessions",
    name: "Sessions",
    icon: "nc-icon nc-cctv",
    component: Sessions,
    layout: "/admin",
  },
];

export default dashboardRoutes;
